















import userManager from "@/services/userManager";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      userManager,
    };
  },
});
